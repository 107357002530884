<template>
  <div class="component-container">
    <h4 v-show="title">{{ title }}</h4>
    <ul class="custom-selector" :class="cssClass">
      <li v-for="option in options" :key="option">
        <Field
          type="radio"
          v-model="mValue"
          :rules="rules"
          :value="option.value"
          :name="name"
          :id="option.label"
          @input="$emit('updated', $event)"
        >
          <input
            type="radio"
            :id="option.label"
            :value="option.value"
            :name="name"
            v-model="mValue"
            @input="$emit('updated', $event)"
          />
          <label :for="option.label">{{ option.label }}</label>
        </Field>
      </li>
    </ul>
    <ErrorMessage :name="name" />
  </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';
import useModelWrapper from '../composables/modelWrapper';

export default {
  name: 'CustomRadioButton',
  emits: ['updated', 'update:modelValue'],
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
    },
    cssClass: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    return {
      mValue: useModelWrapper(props, emit),
    };
  },
};
</script>

<style scoped lang="scss">
ul.custom-selector {
  list-style: none;
  padding: 0;
  margin: 0;

  &.horizontal {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    li {
      flex: 0 0 calc(50% - 10px);
      margin-bottom: 0 !important;
      label {
        justify-content: center;
      }
    }
  }

  li {
    margin: 0 auto;
    width: 100%;
    height: 50px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    input[type='radio'] {
      opacity: 0;
      z-index: 100;
    }

    label {
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      z-index: 90;
      border-radius: 4px;
      color: white;
    }
  }

  label,
  input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
